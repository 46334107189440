<template>
    <div class="dates-container">
        <div class="dates">
            <div
                ref="start_target"
                :class="{ 'has-value': startDate }"
                class="input-group-container start-date mr-1 md:mr-0"
            >
                <label
                    for="form-input-start-date"
                    class="input-group-title has-icon"
                    @click="toggleCalendar('start', true)"
                >
                    {{ packageInfo?.start_date.title ?? null }}
                </label>
                <div
                    class="input-group"
                    :class="{ error: validation ? validation.start.$error : false }"
                    :data-tooltip="packageInfo?.start_date.tooltip"
                >
                    <div class="input-icon-prepend">
                        <NuxtIcon name="ri:calendar-2-line" />
                    </div>
                    <input
                        id="form-input-start-date"
                        type="text"
                        name="start_date"
                        class="tooltip-handle"
                        :class="{ 'calendar-showing': !dateRange && calendarShow.start }"
                        :data-tooltip="packageInfo?.start_date.tooltip"
                        :placeholder="packageInfo?.start_date.placeholder"
                        :value="startDate ? formatDate(startDate, 'day, mmm dd') : null"
                        readonly
                        @click="toggleCalendar('start', true)"
                    />
                    <div class="tooltip">{{ packageInfo?.start_date.tooltip }}</div>
                    <div v-if="validation && validation.start.$error" class="error-tooltip">
                        <span v-if="validation.start?.required?.$invalid">
                            {{ validation.start.required.$message }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="input-arrow">
                <NuxtIcon name="ri:arrow-right-line" />
            </div>
            <div ref="end_target" :class="{ 'has-value': endDate }" class="input-group-container end-date ml-1 md:ml-0">
                <label
                    for="form-input-end-date"
                    class="input-group-title has-icon"
                    @click="toggleCalendar('end', true)"
                >
                    {{ packageInfo?.end_date.title ?? null }}
                </label>
                <div
                    class="input-group"
                    :class="{ error: validation ? validation.end.$error : false }"
                    :data-tooltip="packageInfo?.end_date.tooltip"
                >
                    <div class="input-icon-prepend">
                        <NuxtIcon name="ri:calendar-2-line" />
                    </div>
                    <input
                        id="form-input-end-date"
                        type="text"
                        name="end_date"
                        class="tooltip-handle"
                        :class="{ 'calendar-showing': !dateRange && calendarShow.end }"
                        :data-tooltip="packageInfo?.end_date.tooltip"
                        :placeholder="packageInfo?.end_date.placeholder"
                        :value="
                            endDate && (props.allowSingleRange || startDate != endDate || props.allowSameDate)
                                ? formatDate(endDate, 'day, mmm dd')
                                : null
                        "
                        readonly
                        @click="toggleCalendar('end', true)"
                    />
                    <div class="tooltip">{{ packageInfo?.end_date.tooltip }}</div>
                    <div
                        v-if="validation && validation.end.$error && validation.end.required.$invalid"
                        class="error-tooltip"
                    >
                        {{ validation.end.required.$message }}
                    </div>
                    <div v-if="validation && validation.end.$error" class="error-tooltip custom-date">
                        <span v-if="!validation.end?.required?.$invalid && validation.end?.maxDate?.$invalid">
                            {{ validation.end.maxDate.$message }}
                        </span>
                        <span v-if="validation.end?.checkEndDate?.$invalid">
                            {{ validation.end.checkEndDate.$message }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <LazyUiSearchboxComponentsDatePickerElement
            v-if="!dateRange && calendarShow.start"
            :value="startDate"
            mode="date"
            expanded
            name="start"
            color="primary"
            :calendar-heading="packageInfo?.start_date.tooltip"
            :class="anchor"
            :min-date="new Date()"
            :max-date="new Date(new Date().setMonth(new Date().getMonth() + 12))"
            @toggle-calendar="(value) => toggleCalendar('start', value)"
            @update:value="(value) => (startDate = value)"
        />
        <LazyUiSearchboxComponentsDatePickerElement
            v-if="!dateRange && calendarShow.end"
            :value="endDate"
            mode="date"
            expanded
            name="end"
            color="primary"
            :calendar-heading="packageInfo?.end_date.tooltip"
            :class="anchor"
            :min-date="startDate ? new Date(startDate) : new Date()"
            :max-date="new Date(new Date().setMonth(new Date().getMonth() + 12))"
            @toggle-calendar="(value) => toggleCalendar('end', value)"
            @update:value="(value) => (endDate = value)"
        />
        <LazyUiSearchboxComponentsDateRangePickerElement
            v-if="dateRange && calendarShow.dates"
            :value="dates"
            mode="date"
            expanded
            name="dates"
            color="primary"
            :calendar-headings="packageInfo"
            :class="anchor"
            :max-date="new Date(new Date().setMonth(new Date().getMonth() + 12))"
            :allow-same-date="allowSameDate"
            @toggle-calendar="(value) => toggleCalendar('dates', value)"
            @update:value="(value) => (dates = value)"
        />
    </div>
</template>

<script setup lang="ts">
const props: any = defineProps({
    value: { type: Object, required: false, default: null },
    icon: { type: String, required: false, default: '' },
    packageInfo: { type: Object, required: true, default: () => {} },
    anchor: { type: String, required: false, default: 'left' },
    validation: { type: Object, required: false, default: null },
    allowSingleRange: { type: Boolean, required: false, default: false },
    allowSameDate: { type: Boolean, required: false, default: true },
    dateRange: { type: Boolean, required: false, default: false },
    apr: { type: Boolean, default: false },
});

// Component Refs
const start_target: any = ref(null);
const end_target: any = ref(null);

// Is Mobile?
const isMobile: any = ref(false);

// Resolve Datepicker
const calendarShow: any = ref({
    start: false,
    end: false,
    dates: false,
});

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);

const columnCount = ref(2);
const rowsCount = ref(1);
const startDate: any = ref(props.value.start ? new Date(props.value.start) : null);
const endDate: any = ref(props.value.start ? new Date(props.value.end) : null);
const dates: any = ref({ start: startDate.value, end: endDate.value });

if (!props.allowSameDate && new Date(dates.value.start).toDateString() === new Date(dates.value.end).toDateString()) {
    endDate.value = null;
}

watch(startDate, () => {
    if (startDate.value && endDate.value && new Date(startDate.value) > new Date(endDate.value)) {
        endDate.value = null;
    }
    if (!calendarShow.value.dates) {
        end_target.value.querySelector('input')?.click();
    }
    emit('update:value', { start: startDate.value, end: endDate.value });
});

watch(endDate, () => {
    if (endDate.value === null && props.allowSameDate) {
        endDate.value = startDate.value;
        dates.value.end = startDate.value;
    }
    emit('update:value', { start: startDate.value, end: endDate.value });
});

watch(dates, (value) => {
    startDate.value = value.start;
    endDate.value = value.end;
});

function toggleCalendar(datepicker: string, val: boolean) {
    if (datepicker === 'start') {
        calendarShow.value.start = val;
    } else if (datepicker === 'end') {
        calendarShow.value.end = val;
    }

    calendarShow.value.dates = val;

    if (val) {
        document.body.classList.add('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
    } else {
        document.body.classList.remove('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
    }
}

// Change column count from 1 (mobile) to 2 (desktop/tablet)
function onWidthChange() {
    if (768 < window.innerWidth) {
        columnCount.value = 2;
        rowsCount.value = 1;
        isMobile.value = false;
    } else {
        columnCount.value = 1;
        if (props.apr) rowsCount.value = 12;
        isMobile.value = true;
    }
}

if (import.meta.client) {
    onMounted(() => {
        // Initialize Column Count
        onWidthChange();

        // Watch for column count
        window.addEventListener('resize', onWidthChange);
    });
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));
}
</script>

<style lang="postcss" scoped>
.dates-container {
    @apply relative;

    .input-arrow {
        @apply hidden text-gray-500;
    }

    & :deep(.vc-container) {
        @apply fixed md:absolute md:top-3 bottom-0 md:bottom-auto left-0 md:left-1 right-0 z-[99] min-w-full max-w-[100vw] md:min-w-0 rounded flex flex-col;
    }

    .dates {
        @apply flex justify-center items-center w-full md:min-w-[300px];

        .close {
            @apply hidden;
        }

        /*** Mobile Only ***/
        @media (max-width: theme('screens.md')) {
            &.open {
                @apply fixed top-14 left-0 right-0 bg-white;

                .input-group-title {
                    @apply !left-0 !right-0 text-center !text-xs;
                }

                .input-arrow {
                    @apply block md:hidden relative -top-1;
                }

                .input-group {
                    [data-icon='calendar-days'] {
                        @apply hidden;
                    }
                    input {
                        @apply !px-0 text-center outline-0 !border-none rounded-none;

                        &:focus {
                            @apply !border-b-4 !border-primary-500;
                        }
                    }
                }
            }
        }
    }
}

/* APR mobile version */
.modal-date-selection {
    @apply hidden relative justify-between p-4 w-full;
    border-bottom: 1px solid var(--neutral-light-grey);

    .date-selection {
        &__placeholder {
            margin-bottom: 8px;
            color: var(--text-secondary-grey);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            white-space: nowrap;
        }
        &__value {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            gap: 8px;
            color: var(--text-primary-asphalt);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            .apr-icon-calendar {
                color: var(--text-secondary-grey);
            }
        }
    }

    .date-selection-1.null,
    .date-selection-2.null {
        .date-selection__value {
            color: var(--text-secondary-grey);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .date-selection-1,
    .date-selection-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-weight: 400;
    }

    .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        padding: 0 2px;
        height: 50px;
    }
}
</style>
